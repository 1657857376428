import React from "react"
import Layout from "../components/layout"
import NineXl from "../components/NineXl"
import Brands from "../components/Brands"
import Contact from "../components/Contact"
import SEO from "../components/seo"
import SubHero from "../components/SubHero"
import HomeMain from "../components/HomeMain"
import OrangeCTA from "../components/OrangeCTA"
import { useMagento2MigrationQuery } from "../hooks/useMagento2MigrationQuery"
import YearsInBusiness from "../components/YearsInBusiness"

const Magento2MigrationPage = () => {
  const data = useMagento2MigrationQuery()
  const short = data.allSanityMagento2Migration.edges[0].node
  return (
    <Layout>
      <SEO
        title="Magento 2 Migration"
      />
      <SubHero
        heroImage={short.heroImage.asset.fluid}
        heroTitle={short.heroTitle}
        heroSubtitle={short.heroSubtitle}
        icon1={short.heroIcon1.asset.fluid.src}
      />
      <HomeMain
        mainTitle={short.mainTitle}
        mainText={short._rawMainText}
        mainFeatures={short._rawMainFeatures}
        mainBtn={short.mainBtn}
        slug={"/magento-2-migration/#contact"}
      />
      <NineXl
        icon1={short.services1Icon.asset.fluid.src}
        icon2={short.services2Icon.asset.fluid.src}
        icon3={short.services3Icon.asset.fluid.src}
        icon4={short.services4Icon.asset.fluid.src}
        icon5={short.services5Icon.asset.fluid.src}
        icon6={short.services6Icon.asset.fluid.src}
        icon7={short.services7Icon.asset.fluid.src}
        icon8={short.services8Icon.asset.fluid.src}
        icon9={short.services9Icon.asset.fluid.src}
        title1={short.services1Title}
        title2={short.services2Title}
        title3={short.services3Title}
        title4={short.services4Title}
        title5={short.services5Title}
        title6={short.services6Title}
        title7={short.services7Title}
        title8={short.services8Title}
        title9={short.services9Title}
        text1={short.services1Text}
        text2={short.services2Text}
        text3={short.services3Text}
        text4={short.services4Text}
        text5={short.services5Text}
        text6={short.services6Text}
        text7={short.services7Text}
        text8={short.services8Text}
        text9={short.services9Text}
      />
      <OrangeCTA
        title={short.orangeCtaTitle}
        text={short._rawOrangeCtaText}
        btn={short.orangeCtaBtn}
        overlayImg={short.orangeCtaOverlayImg.asset.fluid}
        slug={"/magento-2-migration/#contact"}
      />
      <Brands />
      <YearsInBusiness />
      <Contact />
    </Layout>
  )
}

export default Magento2MigrationPage
