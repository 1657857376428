import React from "react"

class Nine extends React.Component {
  render() {
    const { icon1, icon2, icon3, icon4, icon5, icon6, icon7, icon8, icon9 } = this.props
    const { title1, title2, title3, title4, title5, title6, title7, title8, title9 } = this.props
    const { text1, text2, text3, text4, text5, text6, text7, text8, text9 } = this.props
    return (
      <div className="my-25 flex flex-col">
        <div className="mx-auto flex max-w-twelve w-full justify-center flex-col items-center lg:flex-row">
          <div className="p-12 flex justify-between flex-col w-5/6 mb:w-4/6 lg:w-2/6 items-center text-center font-hairline">
            <div>
              <img src={icon1} alt={title1} />
            </div>
            <h3 className="text-xl mb-4 w-48">{title1}</h3>
            <p>{text1}</p>
          </div>
          <div className="p-12 flex justify-between flex-col w-5/6 mb:w-4/6 lg:w-2/6 items-center text-center font-hairline">
            <div>
              <img src={icon2} alt={title2} />
            </div>
            <h3 className="text-xl mb-4 w-48">{title2}</h3>
            <p>{text2}</p>
          </div>
          <div className="p-12 flex justify-between flex-col w-5/6 mb:w-4/6 lg:w-2/6 items-center text-center font-hairline">
            <div>
              <img src={icon3} alt={title3} />
            </div>
            <h3 className="text-xl mb-4 w-48">{title3}</h3>
            <p>{text3}</p>
          </div>
        </div>
        <div className="mx-auto flex max-w-twelve w-full justify-center flex-col items-center lg:flex-row">
          <div className="p-12 flex justify-between flex-col w-5/6 mb:w-4/6 lg:w-2/6 items-center text-center font-hairline">
            <div>
              <img src={icon4} alt={title4} />
            </div>
            <h3 className="text-xl mb-4 w-48">{title4}</h3>
            <p>{text4}</p>
          </div>
          <div className="p-12 flex justify-between flex-col w-5/6 mb:w-4/6 lg:w-2/6 items-center text-center font-hairline">
            <div>
              <img src={icon5} alt={title5} />
            </div>
            <h3 className="text-xl mb-4 w-48">{title5}</h3>
            <p>{text5}</p>
          </div>
          <div className="p-12 flex justify-between flex-col w-5/6 mb:w-4/6 lg:w-2/6 items-center text-center font-hairline">
            <div>
              <img src={icon6} alt={title6} />
            </div>
            <h3 className="text-xl mb-4 w-48">{title6}</h3>
            <p>{text6}</p>
          </div>
        </div>
        <div className="mx-auto flex max-w-twelve w-full justify-center flex-col items-center lg:flex-row">
          <div className="p-12 flex justify-between flex-col  w-5/6 mb:w-4/6 lg:w-2/6 items-center text-center font-hairline">
            <div>
              <img src={icon7} alt={title7} />
            </div>
            <h3 className="text-xl mb-4 w-48">{title7}</h3>
            <p>{text7}</p>
          </div>
          <div className="p-12 flex justify-between flex-col w-5/6 mb:w-4/6 lg:w-2/6 items-center text-center font-hairline">
            <div>
              <img src={icon8} alt={title8} />
            </div>
            <h3 className="text-xl mb-4 w-48">{title8}</h3>
            <p>{text8}</p>
          </div>
          <div className="p-12 flex justify-between flex-col w-5/6 mb:w-4/6 lg:w-2/6 items-center text-center font-hairline">
            <div>
              <img src={icon9} alt={title9} />
            </div>
            <h3 className="text-xl mb-4 w-48">{title9}</h3>
            <p>{text9}</p>
          </div>
        </div>
      </div>
    )
  }
}

export default Nine
