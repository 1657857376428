import React from "react"
import BackgroundImage from "gatsby-background-image-es5"

class SubHero extends React.Component {
  render() {
    const { heroImage, heroTitle, heroSubtitle, icon1 } = this.props
    return (
      <div className="relative">
        <BackgroundImage
          fluid={heroImage}
          style={{ backgroundPosition: "top center" }}
        >
          <div
            className="text-white font-hairline flex flex-col items-center justify-center h-xs md:h-sm "
            
          >
            <div className="">
              <div className="flex max-w-xl w-24 h-28 mx-5 mb-0 lg:h-37 lg:w-32">
                <img src={icon1} alt={heroTitle} />
              </div>
            </div>
            <div className="text-center p-5" style={{ maxWidth: "1200px" }}>
              <h1 className="font-hairline text-2xl md:text-3xl mb-5">{heroTitle}</h1>
              <p className="mb-5">{heroSubtitle}</p>
            </div>
          </div>
        </BackgroundImage>
      </div>
    )
  }
}

export default SubHero
