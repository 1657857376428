import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const Brands = props => {
  const data = useStaticQuery(graphql`
    query BrandsQuery {
      allSanityComponents {
        edges {
          node {
            brand1 {
              asset {
                fluid(maxWidth: 160) {
                  ...GatsbySanityImageFluid
                }
              }
            }
            brand2 {
              asset {
                fluid(maxWidth: 160) {
                  ...GatsbySanityImageFluid
                }
              }
            }
            brand3 {
              asset {
                fluid(maxWidth: 160) {
                  ...GatsbySanityImageFluid
                }
              }
            }
            brand4 {
              asset {
                fluid(maxWidth: 160) {
                  ...GatsbySanityImageFluid
                }
              }
            }
            brand5 {
              asset {
                fluid(maxWidth: 160) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
      }
    }
  `)
  const short = data.allSanityComponents.edges[0].node
  return (
    <div className="my-20 lg:my-40 pb-6 font-hairline">
      <div className="max-w-twelve mx-auto flex flex-col p-10px items-center -mt-4 ">
        <h2 className="mb-6 text-2xl">OUR CLIENTS</h2>
        <div className="w-110px border border-2 border-green mb-6"></div>

        <p className="text-base">Driving Sales for Leading Brands</p>
        {/* Swiper container */}
        <div className="flex -mb-20 flex-col md:flex-row">
          <div className="">
            <img
              src={short.brand1.asset.fluid.src}
              style={{ margin: 0, marginLeft: "10px" }}
              alt="brand logo"
            />
          </div>
          <div className="">
            <img src={short.brand2.asset.fluid.src} style={{ margin: 0 }} alt="brand logo" />
          </div>
          <div className="">
            <img src={short.brand3.asset.fluid.src} style={{ margin: 0 }} alt="brand logo" />
          </div>
          <div className="">
            <img src={short.brand4.asset.fluid.src} style={{ margin: 0 }} alt="brand logo" />
          </div>
          <div className="">
            <img
              src={short.brand5.asset.fluid.src}
              style={{ margin: 0, marginTop: "10px", marginLeft: "10px" }}
              alt="brand logo"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Brands
