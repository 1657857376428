import React from "react"
import BackgroundImage from "gatsby-background-image-es5"
import Button from "./Button"
import PortableText from "@sanity/block-content-to-react"

class OrangeCTA extends React.Component {
  render() {
    const { btn, title, text, overlayImg, slug } = this.props

    return (
        <div className="bg-orange text-white">
            <BackgroundImage fluid={overlayImg} style={{ backgroundPosition: "left" }}>
          <div className="lg:py-28 py-20 max-w-twelve mx-auto md:text-left text-center flex flex-col md:flex-row items-center">
            <div className="flex flex-col p-10px w-10/12 md:w-59%">
              <h2 className="mb-6 text-2xl font-hairline">{title}</h2>
              <div className="w-110px border mx-auto md:mx-0 border-2 border-white mb-6"></div>
              <div className="font-hairline">
                <PortableText blocks={text} />
              </div>
            </div>
            <div className="flex flex-col p-10px justify-center items-center md:w-5/12">
              <div className="mx-auto mt-5 md:mt-0">
                <Button btnText={btn} bgColor="bg-brown" slug={slug} />
              </div>
            </div>
          </div>
        </BackgroundImage>
      </div>
    )
  }
}

export default OrangeCTA
