import React from "react"

const YearsInBusiness = ( props ) => {
    return (
      <div style={{ backgroundColor: "#f2f2f2" }}>
        <div className="max-w-twelve mx-auto flex flex-col p-10px py-25 items-center">
          <h2 className="mb-6 text-2xl font-hairline">HAPPY CUSTOMERS TELL GREAT STORIES</h2>
          <div className="w-110px border border-2 border-green mb-6"></div>
          {/* Swiper container */}
          <div className="flex justify-around w-full mt-6">
            <div className="flex flex-col items-center">
              <h2 className="text-4xl text-green font-medium">11</h2>
              <p className="font-hairline" style={{ fontSize: "19px", lineHeight: "2.5" }}>Years In Business</p>
            </div>
            <div className="flex flex-col items-center">
              <h2 className="text-4xl text-green">700+</h2>
              <p className="font-hairline" style={{ fontSize: "19px", lineHeight: "2.5" }}>Clients Served</p>
            </div>
          </div>
        </div>
      </div>
    )

}

export default YearsInBusiness
