import { useStaticQuery, graphql } from "gatsby"
export const useMagento2MigrationQuery = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allSanityMagento2Migration {
          edges {
            node {
              pageTitle
              metadescription
              heroTitle
              heroSubtitle
              mainTitle
              _rawMainText
              mainBtn
              services1Title
              services2Title
              services3Title
              services4Title
              services5Title
              services6Title
              services7Title
              services8Title
              services9Title
              services1Text
              services2Text
              services3Text
              services4Text
              services5Text
              services6Text
              _rawMainFeatures
              services7Text
              services8Text
              services9Text
              orangeCtaTitle
              _rawOrangeCtaText
              orangeCtaBtn
              heroImage {
                asset {
                  fluid {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              heroIcon1 {
                asset {
                  fluid {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              orangeCtaOverlayImg {
                asset {
                  fluid {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              services1Icon {
                asset {
                  fluid {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              services2Icon {
                asset {
                  fluid {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              services3Icon {
                asset {
                  fluid {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              services4Icon {
                asset {
                  fluid {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              services5Icon {
                asset {
                  fluid {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              services6Icon {
                asset {
                  fluid {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              services7Icon {
                asset {
                  fluid {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              services8Icon {
                asset {
                  fluid {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              services9Icon {
                asset {
                  fluid {
                    ...GatsbySanityImageFluid
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  return data
}
