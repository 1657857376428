import React from "react"
import Button from "./Button"
import PortableText from "@sanity/block-content-to-react"
import { FaChevronRight } from "react-icons/fa"

const li = props => {
  return (
    <li style={{ paddingBottom: "15px", marginTop: "15px", display: "flex" }}>
      <i className="flex items-center text-dark">
        <FaChevronRight size="22px" />
      </i>
      <span className="ml-4">{props.children}</span>
    </li>
  )
}

class HomeMain extends React.Component {
  render() {
    const { mainBtn, mainTitle, mainText, mainFeatures, slug } = this.props

    return (
      <div className="my-25 mx-auto font-hairline text-dark">
        <div className="max-w-twelve flex flex-col mx-auto lg:flex-row">
          {/* LEFT CONTAINER */}
          <div
            className="flex-1 flex flex-col"
            style={{ padding: "10px", marginRight: "30px" }}
          >
            <h2 className="mb-5 text-2xl">{mainTitle}</h2>
            <div className="w-110px border border-2 border-green mb-5"></div>
            <PortableText blocks={mainText} />
          </div>
          <div
            className="flex-1 flex flex-col"
            style={{ padding: "10px", marginLeft: "30px" }}
          >
            <ul className="mb-5" style={{ fontSize: "26px" }}>
              {" "}
              <PortableText
                serializers={{ listItem: li }}
                blocks={mainFeatures}
              />
            </ul>
          </div>
        </div>
        <div className="mx-auto md:p-0 px-5" style={{ marginTop: "30px", maxWidth: "280px" }}>
          <Button btnText={mainBtn} bgColor="bg-green" slug={slug} />
        </div>
      </div>
    )
  }
}

export default HomeMain