import React from "react"
import Link from "gatsby-link"

class Button extends React.Component {
  render() {
      const { btnText, bgColor, slug } = this.props
    return (
      <Link to={`${slug}`}>
        <button className={`${bgColor} text-white text-sm md:text-base font-bold rounded-md px-4 md:px-8 md:text-base w-full transition duration-300 ease-in-out  transform hover:scale-110 hover:bg-lightgreen leading-none  `} style={{ paddingTop: "15px", paddingBottom: "15px" }}>
          {btnText}
        </button>
      </Link>
    )
  }
}

export default Button